/**
 * Routes.js
 * A collection of allowable routes on this webpage.
 * Certain routes should be allowed only in authenticated
 * mode while open pages can be viewed in unauth mode as well.
 * All website related routes should go through here.
 * Props passed to this component are wired down to the child
 * components automatically. A few components are dynamic and 
 * are not loaded until the component is actually requested. This
 * gives us good code splitting and chunking when doing prod build.
 * @author Jaiwardhan Swarnakar
 */
import React from 'react'
import Loadable from "react-loadable";
import { Route, Switch } from "react-router-dom";
// import AppliedRoute from './AppliedRoute'
import AuthenticatedRoute from "./AuthenticatedRoute";
import UnauthenticatedRoute from "./UnauthenticatedRoute";
import Loading from './Loading'
import './Routes.css'
import BaseComponent from '../BaseComponent';
import SiteConstants from '../../util/SiteConstants';
import ServiceLocator from '../../ServiceLocator';

class Routes extends BaseComponent {

    AsyncGameInfo = Loadable({
        loader: () => import("../pages/landing/gameInfo" /* webpackChunkName: "LanderPage" */),
        loading: Loading
    })

    AsyncProfile = Loadable({
        loader: () => import("../pages/profile/profile"  /* webpackChunkName: "Profile" */),
        loading: Loading
    })

    Async404 = Loadable({
        loader: () => import("../pages/404/404"  /* webpackChunkName: "404" */),
        loading: Loading
    })
    AsyncLogout = Loadable({
        loader: () => import("../pages/logout/logout" /* webpackChunkName: "Logout" */),
        loading: Loading
    })
    AsyncForgotPassword = Loadable({
        loader: () => import("../minions/login/forgotPassword" /* webpackChunckName: "ForgotPassword" */),
        loading: Loading
    })
    AsyncCreatePassword = Loadable({
        loader: () => import("../minions/zdcMigration/RestoreFacebookFarm" /* webpackChunckName: "ForgotPassword" */),
        loading: Loading
    })
    AsyncEmailRetrieval = Loadable({
        loader: () => import("../minions/zdcMigration/EmailRetrieval" /* webpackChunckName: "EmailRetrieval" */),
        loading: Loading
    })
    AsyncAccountVerification = Loadable({
        loader: () => import("../minions/login/AccountVerification" /* webpackChunckName: "AccountVerification" */),
        loading: Loading
    })
    AsyncAirRedirectUserSelect = Loadable({
        loader: () => import("../pages/platform/air/redirect/UserSelect" /* webpackChunckName: "AirZDCUserSelect" */),
        loading: Loading
    })

    /** ================== Games ==================== */
    AsyncFarmvilleTwo = Loadable({
        loader: () => import("../pages/games/farmville-two/iframe"  /* webpackChunkName: "Farm2" */),
        loading: Loading
    });
    /** ============================================= */

    /** ================== Dev env games ============ */
    AsyncFarmTwoR01 = Loadable({
        loader: () => import("../pages/games/farm_two_r_one/iframe"  /* webpackChunkName: "Farm2Devapps" */),
        loading: Loading
    });

    AsyncFarmTwoR02 = Loadable({
        loader: () => import("../pages/games/farm_two_r_two/iframe"  /* webpackChunkName: "Farm2Devapps" */),
        loading: Loading
    });

    AsyncFarmTwoF20 = Loadable({
        loader: () => import("../pages/games/farm_two_f_twenty/iframe"  /* webpackChunkName: "Farm2Devapps" */),
        loading: Loading
    });

    AsyncFarmTwoF21 = Loadable({
        loader: () => import("../pages/games/farm_two_f_twoone/iframe"  /* webpackChunkName: "Farm2Devapps" */),
        loading: Loading
    });

    AsyncFarmTwoF22 = Loadable({
        loader: () => import("../pages/games/farm_two_f_twotwo/iframe"  /* webpackChunkName: "Farm2Devapps" */),
        loading: Loading
    });

    AsyncFarmTwoF23 = Loadable({
        loader: () => import("../pages/games/farm_two_f_twothree/iframe"  /* webpackChunkName: "Farm2Devapps" */),
        loading: Loading
    });

    AsyncFarmTwoF24 = Loadable({
        loader: () => import("../pages/games/farm_two_f_twofour/iframe"  /* webpackChunkName: "Farm2Devapps" */),
        loading: Loading
    });

    AsyncFarmTwoF25 = Loadable({
        loader: () => import("../pages/games/farm_two_f_twofive/iframe"  /* webpackChunkName: "Farm2Devapps" */),
        loading: Loading
    });

    AsyncFarmTwoF26 = Loadable({
        loader: () => import("../pages/games/farm_two_f_twosix/iframe"  /* webpackChunkName: "Farm2Devapps" */),
        loading: Loading
    });

    AsyncFarmTwoF27 = Loadable({
        loader: () => import("../pages/games/farm_two_f_twoseven/iframe"  /* webpackChunkName: "Farm2Devapps" */),
        loading: Loading
    });

    AsyncFarmTwoF28 = Loadable({
        loader: () => import("../pages/games/farm_two_f_twoeight/iframe"  /* webpackChunkName: "Farm2Devapps" */),
        loading: Loading
    });

    AsyncFarmTwoF29 = Loadable({
        loader: () => import("../pages/games/farm_two_f_twonine/iframe"  /* webpackChunkName: "Farm2Devapps" */),
        loading: Loading
    });

    AsyncFarmTwoF30 = Loadable({
        loader: () => import("../pages/games/farm_two_f_threezero/iframe"  /* webpackChunkName: "Farm2Devapps" */),
        loading: Loading
    });

    AsyncFarmTwoP02 = Loadable({
        loader: () => import("../pages/games/farm_two_p_two/iframe"  /* webpackChunkName: "Farm2Devapps" */),
        loading: Loading
    });

    AsyncFarmTwoP03 = Loadable({
        loader: () => import("../pages/games/farm_two_p_three/iframe"  /* webpackChunkName: "Farm2Devapps" */),
        loading: Loading
    });

    /** ============================================= */

    render() {

        return (
            <div id="uwgPageRouter" className="routerContainer">
                <Switch id="switchId">
                    <UnauthenticatedRoute
                        path="/"
                        exact
                        component={this.AsyncGameInfo}
                        props={this.props}
                    />
                    <UnauthenticatedRoute
                        path="/logout"
                        exact
                        component={this.AsyncLogout}
                        props={this.props}
                    />
                    <UnauthenticatedRoute
                        path="/forgot_password"
                        exact
                        component={this.AsyncForgotPassword}
                        props={this.props}
                    />
                    <UnauthenticatedRoute
                        path="/create_password"
                        exact
                        component={this.AsyncCreatePassword}
                        props={this.props}
                    />
                    <UnauthenticatedRoute
                        path="/play/farmville-two/select"
                        exact
                        component={this.AsyncAirRedirectUserSelect}
                        props={this.props}
                    />
                    <AuthenticatedRoute
                        path="/gdpr_verification"
                        exact
                        component={this.AsyncAccountVerification}
                        props={this.props}
                    />
                    <AuthenticatedRoute
                        path="/profile/[0-9]*[\/]*"
                        exact
                        component={this.AsyncProfile}
                        props={this.props}
                    />
                    <AuthenticatedRoute
                        path="/profile/me"
                        component={this.AsyncProfile}
                        props={this.props}
                    />
                    <AuthenticatedRoute
                        path="/email_retrieval"
                        component={this.AsyncEmailRetrieval}
                        props={this.props}
                    />

                    {/* All routes to the games should be specified as Authenticated Routes */}
                    {/* Which will automatically take care of the user authentication state. */}
                    <AuthenticatedRoute
                        path={SiteConstants.UrlCodes.Games.Farm2.GAME_URL}
                        component={this.AsyncFarmvilleTwo}
                        props={this.props}
                    />

                    {/* Internal apps - VPN ONLY */}
                    <AuthenticatedRoute
                        path={ServiceLocator.Env.GameMaps.Get("Farm2", "farm_two_r_one").href}
                        exact
                        component={this.AsyncFarmTwoR01}
                        props={this.props}
                    />

                    <AuthenticatedRoute
                        path={ServiceLocator.Env.GameMaps.Get("Farm2", "farm_two_r_two").href}
                        exact
                        component={this.AsyncFarmTwoR02}
                        props={this.props}
                    />

                    <AuthenticatedRoute
                        path={ServiceLocator.Env.GameMaps.Get("Farm2", "farm_two_f_twenty").href}
                        exact
                        component={this.AsyncFarmTwoF20}
                        props={this.props}
                    />

                    <AuthenticatedRoute
                        path={ServiceLocator.Env.GameMaps.Get("Farm2", "farm_two_f_twoone").href}
                        exact
                        component={this.AsyncFarmTwoF21}
                        props={this.props}
                    />

                    <AuthenticatedRoute
                        path={ServiceLocator.Env.GameMaps.Get("Farm2", "farm_two_f_twotwo").href}
                        exact
                        component={this.AsyncFarmTwoF22}
                        props={this.props}
                    />

                    <AuthenticatedRoute
                        path={ServiceLocator.Env.GameMaps.Get("Farm2", "farm_two_f_twothree").href}
                        exact
                        component={this.AsyncFarmTwoF23}
                        props={this.props}
                    />

                    <AuthenticatedRoute
                        path={ServiceLocator.Env.GameMaps.Get("Farm2", "farm_two_f_twofour").href}
                        exact
                        component={this.AsyncFarmTwoF24}
                        props={this.props}
                    />

                    <AuthenticatedRoute
                        path={ServiceLocator.Env.GameMaps.Get("Farm2", "farm_two_f_twofive").href}
                        exact
                        component={this.AsyncFarmTwoF25}
                        props={this.props}
                    />

                    <AuthenticatedRoute
                        path={ServiceLocator.Env.GameMaps.Get("Farm2", "farm_two_f_twosix").href}
                        exact
                        component={this.AsyncFarmTwoF26}
                        props={this.props}
                    />

                    <AuthenticatedRoute
                        path={ServiceLocator.Env.GameMaps.Get("Farm2", "farm_two_f_twoseven").href}
                        exact
                        component={this.AsyncFarmTwoF27}
                        props={this.props}
                    />

                    <AuthenticatedRoute
                        path={ServiceLocator.Env.GameMaps.Get("Farm2", "farm_two_f_twoeight").href}
                        exact
                        component={this.AsyncFarmTwoF28}
                        props={this.props}
                    />

                    <AuthenticatedRoute
                        path={ServiceLocator.Env.GameMaps.Get("Farm2", "farm_two_f_twonine").href}
                        exact
                        component={this.AsyncFarmTwoF29}
                        props={this.props}
                    />

                    <AuthenticatedRoute
                        path={ServiceLocator.Env.GameMaps.Get("Farm2", "farm_two_f_threezero").href}
                        exact
                        component={this.AsyncFarmTwoF30}
                        props={this.props}
                    />

                    <AuthenticatedRoute
                        path={ServiceLocator.Env.GameMaps.Get("Farm2", "farm_two_p_two").href}
                        exact
                        component={this.AsyncFarmTwoP02}
                        props={this.props}
                    />

                    <AuthenticatedRoute
                        path={ServiceLocator.Env.GameMaps.Get("Farm2", "farm_two_p_three").href}
                        exact
                        component={this.AsyncFarmTwoP03}
                        props={this.props}
                    />
                    <UnauthenticatedRoute
                        path="/account_verification"
                        exact
                        component={this.AsyncGameInfo}
                        props={this.props}
                    />

                    {/* Finally all other unknown routes redirect to a 404 */}
                    <Route component={this.Async404} />
                </Switch>
            </div>
        )
    }

}

export default Routes;